<template>
  <div>
    <Navbar />

    <div class="mainCOntainer pb-4">
      <Medicaments />
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import Medicaments from "./Medicaments.vue";
export default {
  name: "exclusiveMedicaments",
  components: {
    Navbar,
    Footer,
    Medicaments,
  },
};
</script>

<style scoped>
.mainCOntainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  min-height: calc(100vh - 190px);
}
@media all and (max-width: 1500px) {
  .mainCOntainer {
    padding: 0px 20px 0px 20px;
  }
}
</style>