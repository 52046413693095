<template>
  <div>
    <div class="form-outline seacrh mt-3 mb-3">
      <input
        type="search"
        id="form1"
        class="form-control"
        :placeholder="$t('front.search')"
        aria-label="Search"
        v-model="search"
      />
    </div>

    <div class="container Medicaments">
      <div class="row">
        <div
          class="col-sm-3 col-md-12 col-sm-12 pt-4 pb-4 col-lg-3"
          style="border-bottom: 1px solid #9999"
        >
          <h5>
            {{ $t("medicamentsDescription.categoryMedicaments") }}
          </h5>
          <div class="categories modalContains" v-if="!isLoading">
            <hr style="width: 80%" />
            <div class="categoryList" v-for="item in menus" :key="item._id">
              <li
                v-if="lang == 'ka'"
                class="pb-1"
                v-html="item.titleKA"
                :style="`font-weight: ${
                  item === name_of_chapter ? 'bold' : 'normal'
                }`"
                @click="
                  () => {
                    changeDropListSlot(item);
                    goto('div1');
                  }
                "
              >
                <!-- <li
                v-if="lang == 'ka'"
                class="pb-1"
                v-html="item.titleKA"
                :style="`font-weight: ${
                  item === name_of_chapter ? 'bold' : 'normal'
                }`"
                @click="changeDropListSlot(item)"
              >
                {{ item.titleKA }}
              </li> -->
              </li>

              <li
                style="text-transform: uppercase"
                v-else
                class="pb-1"
                v-html="item.titleEN"
                :style="`font-weight: ${
                  item === name_of_chapter ? 'bold' : 'normal'
                }`"
                @click="
                  () => {
                    changeDropListSlot(item);
                    goto('div1');
                  }
                "
              >
                {{ item.titleEN }}
              </li>
            </div>
          </div>
          <div v-else>loading</div>
        </div>
        <div class="col-sm content" ref="div1">
          <LogoText />
          <div class="pt-3 pl-3" style="color: #7e5493">
            <h5 style="text-transform: uppercase">
              {{ $t("medicamentsDescription.categorySimple") }}
              <span
                ><img
                  style="width: 15px"
                  src="../../.././../assets/icons/rightArrow.png"
                  alt="arrow"
              /></span>
              {{
                lang == "ka" ? name_of_chapter.titleKA : name_of_chapter.titleEN
              }}
            </h5>
          </div>

          <MedicamentsCards
            :name_of_chapter="name_of_chapter"
            :search="search"
            @childvalue="
              (blog) => {
                latest_blog = blog;
              }
            "
          />

          <div v-if="latest_blog != null" class="col pr-0 pl-0 image">
            <div
              v-if="latest_blog.video != null"
              class="p-0 pr-0 pr-0"
              style="positon: relative; height: 300px; width: 100%"
            >
              <iframe
                width="100%"
                height="100%"
                :src="latest_blog.video"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div
              v-else
              class="p-0 pr-0 pr-0"
              style="positon: relative; height: 300px; width: 100%"
            >
              <div
                class="col image"
                :style="`backgroundImage: url(${server_url}/${latest_blog.image});`"
              ></div>
            </div>
            <div
              style="
                position: absolute;
                display: grid;
                align-items: flex-end;
                height: 100%;
                width: 100%;
                bottom: 0px;
                left: 16px;
              "
              class="col-12 col-md-5 col-sm-8 p-0 m-0"
            >
              <div class="mtavruliBOLD d-grid blogTEXT" style="color: white">
                <div>
                  <span
                    v-if="lang == ka"
                    v-html="latest_blog.titleKA"
                    class="mtavruliBOLD blogTitle"
                    style="font-size: 30px; color: white"
                  >
                  </span>
                  <span
                    v-else
                    v-html="latest_blog.titleEN"
                    class="mtavruliBOLD blogTitle"
                    style="font-size: 30px; color: white"
                  >
                  </span>
                </div>
                <span
                  v-if="lang == ka"
                  v-html="latest_blog.subTitleKA"
                  class="mtavruliBOLD blogTitle2"
                >
                </span>
                <span
                  v-else
                  v-html="latest_blog.subTitleEN"
                  class="mtavruliBOLD blogTitle2"
                >
                </span>
              </div>

              <div class="d-flex">
                <router-link :to="'/blog' + '/' + latest_blog.slug">
                  <button
                    class="btn btn-primary pl-4 pr-4 pt-2 blogButton"
                    type="submit"
                    style="margin-bottom: 30px; text-transform: uppercase"
                  >
                    {{ $t("front.more") }}
                  </button></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicamentsCards from "./MedicamentsCards.vue";
import LogoText from "./LogoText.vue";
import axios from "axios";
import env from "../../../../env.json";
export default {
  components: {
    MedicamentsCards,
    LogoText,
  },
  data() {
    return {
      name_of_chapter: null,
      lang: "ka",
      isLoading: false,
      menus: [],
      latest_blog: null,
      search: "",
      server_url: env.server_url,
      category_id: "",
    };
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    gotoFromSearch(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    changeDropListSlot(item, refName) {
      this.name_of_chapter = item;
    },
  },
  mounted() {
    this.isLoading = true;
    axios.get(`${env.host}/get/all/categories/user`).then((result) => {
      console.log(result.data.item);
      this.menus = [...result.data.item];
      this.name_of_chapter = result.data.item[0];
      this.isLoading = false;
    });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
* {
  color: #666666;
}
.blogTitle2 >>> * {
  color: white !important;
  font-family: mtavruliBOLD;
}
.blogTitle >>> * {
  color: white !important;
  font-family: mtavruliBOLD;
}
.blogHeader >>> * {
  font-family: mtavruliBOLD !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b3b3b313;
}
::-webkit-scrollbar-thumb {
  background: #a480b7a6;
}
::-webkit-scrollbar-thumb:hover {
  background: #a480b7;
}
.mainCOntainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  min-height: calc(100vh - 190px);
}
@media all and (max-width: 1500px) {
  .mainCOntainer {
    padding: 0px 20px 0px 20px;
  }
}
.Medicaments {
  background-color: #f5f2f4;
  width: 100%;
  max-width: 1400px;
}
.categories {
  list-style-type: none;
}
h5 {
  color: #7e5493;
}
.modalContains {
  overflow-y: scroll;
  max-height: 1190px !important;
}
.col-sm {
  padding: 0px;
}
.blogTEXT {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.image {
  height: 300px;
  background-position: center;
  background-size: cover;
}
.btn-primary {
  border: none;
  color: #7e5493;
  font-weight: bold;
  background-color: white;
  letter-spacing: 1px;
  font-family: mtavruli;
}
.blogButton {
  margin-bottom: auto;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.categoryList li {
  cursor: pointer;
}
.categoryList li:hover {
  font-weight: bold;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493;
  border-color: #7e5493;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.form-control {
  border-radius: 0px;
}
.form-control:focus {
  border-color: #7e5493;
  box-shadow: none;
}
@media all and (max-width: 1500px) {
  .modalContains {
    overflow-y: scroll;
    max-height: 1190px !important;
  }
}
@media all and (max-width: 991px) {
  .modalContains {
    overflow-y: scroll;
    max-height: 600px !important;
  }
}
</style>