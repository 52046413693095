<template>
  <div>
    <div v-if="!isLoading" class="row pb-5 row-cols-lg-2 g-2 g-lg-3">
      <div
        class="col col-sm-6 col-md-12 col-sm-12 mt-4 col-12"
        v-for="item in items"
        :key="item.id"
      >
        <div>
          <div
            class="card"
            :style="`height: 20rem;  backgroundImage: url(${server_url}/${item.images[0].image});`"
          ></div>
          <div>
            <div
              v-if="lang == 'ka'"
              v-html="item.titleKA"
              class="
                purple
                medicamentsName
                mtavruliBOLD
                justify-content-center
                d-flex
                pt-1
                pb-1
              "
              style="font-family: mtavruliBOLD; text-transform: uppercase"
            >
              {{ item.titleKA }}
            </div>
            <div
              v-else
              v-html="item.titleEN"
              class="
                purple
                medicamentsName
                mtavruliBOLD
                justify-content-center
                d-flex
                pt-1
                pb-1
              "
              style="font-family: mtavruliBOLD; text-transform: uppercase"
            >
              {{ item.titleEN }}
            </div>
            <router-link
              :to="
                '/' +
                item.category.titleEN +
                '/' +
                item.slug +
                '/medicamentsDetail'
              "
            >
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block align-self-end"
              >
                {{ $t("front.more") }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding: 20px">
      <div class="row pb-5 row-cols-lg-2 g-2 g-lg-3">
        <div
          class="col col-sm-6 col-md-12 col-sm-12 mt-4 col-12"
          v-for="item in [1, 2, 3, 4]"
          :key="item"
        >
          <b-skeleton-img
            no-aspect
            height="312px"
            type="input"
          ></b-skeleton-img>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <b-pagination
        v-if="items.length > 0"
        v-model="currentPage"
        :total-rows="paginationLength"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <div
      class="mb-5 ml-3"
      style="text-transform: uppercase"
      v-if="items.length == 0"
    >
      {{ $t("medicamentsDescription.nocategoryFind") }}
    </div>
  </div>
</template>
<script>
import axios from "axios";
import env from "../../../../env.json";
export default {
  data() {
    return {
      // cssProps: {
      //   backgroundImage: `url(${require("../../../../assets/img/med.jpg")})`,
      // },
      // image: ["../../../../assets/img/abbvie-flask-arrangement-1920x987 2.png"],
      category: "medicaments",
      currentPage: 1,
      perPage: 4,
      paginationLength: 4,
      isLoading: false,
      choosedCategory: "",
      lang: "ka",
      items: [],
      latest_blog: {},
      server_url: env.server_url,
      timer: null,
      timeout: 500,
    };
  },
  props: ["name_of_chapter", "search"],
  watch: {
    name_of_chapter: {
      deep: true,
      handler: function (newVal) {
        this.isLoading = true;
        axios
          .get(
            `${env.host}/get/filter-by-pagination/products/${newVal._id}/${this.currentPage}/${this.perPage}`
          )
          .then((result) => {
            console.log(this.items, "checked");
            this.latest_blog = result.data.latest_blog;
            this.items = result.data.item;
            console.log(result.data.latest_blog, "latest blog");
            this.paginationLength = result.data.total_page;
            this.$emit("childvalue", result.data.latest_blog);
            this.isLoading = false;
          });
        if (localStorage.getItem("lang") == "ka") {
          this.lang = "ka";
        } else {
          this.lang = "en";
        }
      },
    },
    search: function (newValue) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.currentPage = 1;
        this.perPage = 4;
        const valuedUrl = `${env.host}/search/products/filter-by-pagination/${this.name_of_chapter._id}/${newValue}/${this.currentPage}/${this.perPage}`;
        const nonValuedUrl = `${env.host}/get/filter-by-pagination/products/${this.name_of_chapter._id}/${this.currentPage}/${this.perPage}`;
        axios.get(`${newValue ? valuedUrl : nonValuedUrl}`).then((result) => {
          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        });
      }, this.timeout);
    },
    // currentPage: function (newValue) {
    //   this.isLoading = true;
    //   axios
    //     .get(
    //       `${env.host}/get/filter-by-pagination/products/${this.name_of_chapter._id}/${this.currentPage}/${this.perPage}`
    //     )
    //     .then((result) => {
    //       this.latest_blog = result.data.latest_blog;
    //       this.items = [...result.data.item];
    //       this.paginationLength = result.data.total_page;

    //       this.isLoading = false;
    //     })
    //     .catch((err) => console.log(err));
    //   console.log(this.latest_blog, "latest");
    // },
  },
  mounted() {
    this.isLoading = true;
    axios
      .get(
        `${env.host}/get/filter-by-pagination/products/${this.name_of_chapter._id}/1/4`
      )
      .then((result) => {
        this.items = [...result.data.item];
        this.latest_blog = result.data.latest_blog;
        this.$emit("childvalue", result.data.latest_blog);
        this.paginationLength = result.data.total_page;
        this.isLoading = false;
        console.log(result.data.item.category.titleEN + "es gamovida");
      });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
* {
  font-family: mtavruli;
}
.medicamentsName >>> * {
  font-family: mtavruliBOLD;
}
.card-body {
  padding: 0px;
}
.container {
  width: 100%;
  max-width: 1400px;
  padding: 0px;
}
.card {
  /* background-image: url("../../../../assets/img/med.jpg"); */
  width: 100%;
  /* height: 18rem; */
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 0px;
  border-radius: 0px;
  align-items: center;
  /* font-family: QARTULI !important; */
}
.card img {
  /* width: 90%; */
  height: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 0px;
  border-radius: 0px;
  align-items: center;
}
.button.btn {
  padding: 0px;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e54939f;
  border-color: #7e5493;
  padding: 15px;
  text-transform: uppercase;
  border: 0px;
}
.withOut {
  height: 60px;
  padding: 0px;
  text-align: center;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493;
  border-color: #7e5493;
}
.btn-primary:hover {
  background-color: #7e5493;
  border-color: #7e5493;
}
a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}
::v-deep .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #7e54935b !important;
}
::v-deep .page-link {
  color: #bdbdbd;
}
::v-deep .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7e5493 !important;
  border-color: #7e5493 !important;
}
::v-deep .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #7e54935b !important;
}
</style>